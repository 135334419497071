@charset "UTF-8";
/*Шрифты */
@font-face {
  font-family: 'SF-UI-Display-Bold';
  src: url("../fonts/SF-UI-Display-Bold.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/SF-UI-Display-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/SF-UI-Display-Bold.woff2") format("woff2"), url("../fonts/SF-UI-Display-Bold.woff") format("woff"), url("../fonts/SF-UI-Display-Bold.ttf") format("truetype"), url("../fonts/SF-UI-Display-Bold.svg#svgFontName") format("svg");
  /* Legacy iOS */ }

@font-face {
  font-family: 'SF-UI-Display-Heavy';
  src: url("../fonts/SF-UI-Display-Heavy.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/SF-UI-Display-Heavy.eot?#iefix") format("embedded-opentype"), url("../fonts/SF-UI-Display-Heavy.woff2") format("woff2"), url("../fonts/SF-UI-Display-Heavy.woff") format("woff"), url("../fonts/SF-UI-Display-Heavy.ttf") format("truetype"), url("../fonts/SF-UI-Display-Heavy.svg#svgFontName") format("svg");
  /* Legacy iOS */ }

@font-face {
  font-family: 'SF-UI-Display-Medium';
  src: url("../fonts/SF-UI-Display-Medium.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/SF-UI-Display-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/SF-UI-Display-Medium.woff2") format("woff2"), url("../fonts/SF-UI-Display-Medium.woff") format("woff"), url("../fonts/SF-UI-Display-Medium.ttf") format("truetype"), url("../fonts/SF-UI-Display-Medium.svg#svgFontName") format("svg");
  /* Legacy iOS */ }

@font-face {
  font-family: 'SF-UI-Display-Regular';
  src: url("../fonts/SF-UI-Display-Regular.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/SF-UI-Display-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/SF-UI-Display-Regular.woff2") format("woff2"), url("../fonts/SF-UI-Display-Regular.woff") format("woff"), url("../fonts/SF-UI-Display-Regular.ttf") format("truetype"), url("../fonts/SF-UI-Display-Regular.svg#svgFontName") format("svg");
  /* Legacy iOS */ }

@font-face {
  font-family: 'SF-UI-Display-Light';
  src: url("../fonts/SF-UI-Display-Light.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/SF-UI-Display-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/SF-UI-Display-Light.woff2") format("woff2"), url("../fonts/SF-UI-Display-Light.woff") format("woff"), url("../fonts/SF-UI-Display-Light.ttf") format("truetype"), url("../fonts/SF-UI-Display-Light.svg#svgFontName") format("svg");
  /* Legacy iOS */ }

/* Спецификация */
p, button, h1, h2, ul, li {
  padding: 0px;
  margin: 0px; }

ul, li {
  display: block; }

a, a:hover, a:active, a:focus,
button, button:hover, button:active, button:focus,
input, input:hover, input:active, input:focus,
textarea, textarea:hover, textarea:active, textarea:focus {
  text-decoration: none;
  outline: none; }

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s; }

html {
  overflow-x: hidden;
  height: 100%; }

body {
  font-family: 'SF-UI-Display-Regular', sans-serif;
  font-size: 16px;
  color: #555;
  height: 100%;
  display: flex;
  flex-direction: column; }

#wrapper {
  flex: 1 0 auto;
  background: #f7f9f9; }

h1 {
  font-family: 'SF-UI-Display-Heavy';
  font-size: 52.5px;
  line-height: 56px;
  text-transform: uppercase;
  margin: 100px 0 17px 0;
  transition: all 0.3s ease; }
  h1 span {
    font-family: 'SF-UI-Display-Light';
    font-size: 43px;
    letter-spacing: 0.03em; }

h2 {
  font-family: 'SF-UI-Display-Heavy';
  font-size: 36px;
  text-transform: uppercase;
  line-height: 45px;
  color: #252525; }
  h2 span {
    font-family: 'SF-UI-Display-Regular';
    font-size: 30px;
    line-height: 0px; }

.container {
  position: relative; }

.button {
  display: block;
  width: 195px;
  height: 49px;
  background: #01D3EE;
  box-shadow: 0px 0 0px 4px rgba(1, 211, 238, 0.2);
  border-radius: 24.5px;
  text-align: center;
  cursor: pointer;
  padding: 0px 0;
  font-size: 17px;
  letter-spacing: 0.03em;
  padding: 13px 0; }
  .button .arrow-right {
    display: none;
    width: 7px;
    height: 9px;
    overflow: hidden;
    margin: 0 0 0 5px;
    position: relative; }
  .button .arrow-right img {
    position: absolute;
    top: 0;
    left: 0; }

.button, .button:hover, .button:active, .button:focus {
  color: #fff; }

.button:hover .arrow-right {
  display: inline-block; }

.form-button {
  width: 495px;
  height: 49px;
  border-radius: 5px;
  background: #01D3EE;
  text-align: center;
  font-size: 17px;
  color: #fff;
  border: none;
  transition: all 0.4s ease; }

.form-button:hover {
  background: #04c5de;
  transform: translateY(-3px); }

/*ПЕРВЫЙ ЭКРАН*/
.fullscreen-bg {
  background: url(../img/bg.png) no-repeat center top/cover;
  color: #fff;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-top: 840px;
  transition: all 0.4s ease; }

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: none;
  margin-top: -85px; }

.fullscreen-bg-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 4;
  padding: 19px 0 360px 0; }

.waves {
  position: absolute;
  bottom: -1px;
  z-index: 10;
  transition: all 0.3s ease; }

.waves img {
  transition: all 0.4s ease; }

.header, .header_new-login, nav ul {
  display: flex;
  align-items: center; }

nav {
  margin: 0 90px 0 90px; }
  nav li:not(:last-child) {
    margin: 0 22px 0 0px; }
  nav a {
    position: relative;
    display: block;
    cursor: pointer;
    padding: 10px 3.49px;
    color: #fff;
    text-decoration: none;
    font-family: 'SF-UI-Display-Regular';
    font-size: 13px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    transition: font-family 0.3s ease; }
  nav li:nth-child(2) a {
    padding: 10px 7.14px; }
  nav li:nth-child(4) a {
    padding: 10px 3.67px; }
  nav li:nth-child(2) a:hover, nav li:nth-child(4) a:hover {
    padding: 10px 0px; }
  nav a:hover, nav a:active {
    font-family: 'SF-UI-Display-Heavy';
    color: #fff;
    text-decoration: none;
    padding: 10px 0px; }
  nav a:focus {
    color: #fff; }
  nav a:before, nav a:after {
    content: '';
    position: absolute;
    width: 0%;
    height: 2px;
    bottom: -2px;
    background: #fff; }
  nav a:before {
    left: 0; }
  nav a:after {
    right: 0;
    background: #fff;
    transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1); }
  nav a:hover:before {
    background: #fff;
    width: 100%;
    transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1); }
  nav a:hover:after {
    background: transparent;
    width: 100%;
    transition: 0s; }

.header_info {
  display: flex;
  align-items: center; }

.open {
  position: relative; }

.open img:first-child {
  animation: spin 12s infinite linear; }

.open img:first-child:hover {
  animation-play-state: paused; }

.open img:last-child {
  position: absolute;
  top: 10px;
  left: -6px; }

@keyframes spin {
  from {
    transform: rotateZ(0deg); }
  to {
    transform: rotateZ(360deg); } }

.header_new-login {
  font-size: 14px;
  letter-spacing: 0.03em;
  margin: 0 0 0 54px; }

.header_new, .header_login {
  cursor: pointer; }

.header_login {
  position: relative;
  z-index: 2;
  transition: color 1s ease; }

.header_login:hover {
  color: #01D3EE;
  transition: color 1s ease; }

.header_login:after {
  content: "";
  width: 3px;
  height: 1px;
  left: 0;
  bottom: 0;
  position: absolute;
  z-index: -1;
  transition: all 1s ease 0s; }

.header_login:hover:after {
  width: 100%;
  height: 1px;
  background: #01D3EE; }

.header_new {
  width: 126px;
  height: 32px;
  border: 1px solid #01D3EE;
  border-radius: 15px;
  text-align: center;
  color: #01D3EE;
  padding: 4px 0;
  margin: 0 0 0 17px; }

.header_new:hover {
  color: #fff;
  background: #01D3EE; }

.hamburger {
  display: none;
  position: relative; }

.hamburger_icon {
  cursor: pointer; }

.hamburger_menu {
  display: none;
  position: absolute;
  right: 0;
  background: #fff;
  text-align: right;
  padding: 0;
  margin: 18px 0 0 0;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 225px;
  z-index: 10; }
  .hamburger_menu:before {
    display: block;
    content: "";
    border-left: 8.5px solid transparent;
    border-right: 8.5px solid transparent;
    border-bottom: 9px solid #fff;
    position: absolute;
    top: -9px;
    right: 8px; }
  .hamburger_menu li {
    padding: 12px 18px 0 18px; }
  .hamburger_menu li a {
    display: block;
    padding: 0 4px 12px 0px;
    border-bottom: 1px solid #eee;
    font-family: SF-UI-Display-Regular;
    font-size: 13px;
    color: #252525;
    text-transform: uppercase;
    transition: margin-bottom .3s ease, border-bottom .2s ease; }
  .hamburger_menu li:hover a {
    border-bottom: 2px solid #01D3EE;
    font-family: 'SF-UI-Display-Bold'; }
  .hamburger_menu li:last-child:hover a {
    margin-bottom: 18px; }

.offer_text-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.offer_text {
  font-family: 'SF-UI-Display-Light';
  font-size: 17px;
  line-height: 26px;
  letter-spacing: 0.03em;
  width: 603px;
  margin: 0 0 35px 0;
  transition: opacity 0.3s ease; }
  .offer_text:before {
    content: "";
    display: block;
    width: 40px;
    height: 2px;
    background: #fff;
    margin: 0 0 20px 0; }

.offer .button {
  transition: all 0.3s ease; }

.video-button {
  position: relative; }

.play, .pause {
  width: 40px;
  height: 40px;
  background: #01D3EE;
  border-radius: 50%;
  box-shadow: 0px 0 0px 8px rgba(1, 211, 238, 0.2);
  text-align: center;
  padding: 10px 0 10px 5px;
  cursor: pointer;
  transition: all 0.3s ease; }

.pause {
  position: absolute;
  top: 0;
  padding: 10px 0;
  opacity: 0;
  display: none;
  transition: all 0.3s ease; }

/*ВТОРОЙ ЭКРАН*/
#about {
  background: #fff;
  background-size: initial;
  overflow: hidden;
  position: relative; }
  #about h2 {
    width: 263px; }

.bg-img {
  position: absolute; }

.map {
  margin: 23px 0 0 373px; }

.about-info_text {
  color: #252525;
  line-height: 24px;
  margin: 15px 0 0 0; }
  .about-info_text:before {
    content: "";
    display: block;
    width: 40px;
    height: 2px;
    background: #048c96;
    margin: 0 0 15px 0; }

.achievements {
  margin: -25px 0 195px 80px; }

.achievement {
  display: flex;
  align-items: center; }

.achievement:not(:first-child) {
  margin: -13px 0 0 0; }

.achievement_info {
  display: flex;
  align-items: center; }

.more {
  display: inline-block;
  overflow: hidden;
  width: 1.5em;
  font-family: 'SF-UI-Display-Light';
  font-size: 18px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #048c96; }

.more_inner {
  display: inline-block;
  white-space: nowrap;
  transform: translate(0, 100%) rotate(-90deg);
  transform-origin: 0 0; }

.more_inner:before {
  content: "";
  float: left;
  margin-top: 100%; }

.achievement_number {
  font-family: 'SF-UI-Display-Heavy';
  font-size: 90px;
  letter-spacing: -0.01em;
  color: #048c96;
  margin: 0 40px 0 10px; }

.achievement:nth-child(1) .achievement_number {
  margin: 0 110px 0 10px; }

.achievement:nth-child(2) .achievement_text {
  width: 200px; }

.achievement:nth-child(3) .achievement_text {
  width: 332px; }

.achievement_text {
  font-family: 'SF-UI-Display-Medium';
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0.025em;
  text-transform: uppercase; }

/*ТРЕТИЙ ЭКРАН*/
#advantages {
  padding: 0 0 112px 0;
  background: #fff; }

.advantages_list-main {
  display: flex;
  flex-wrap: wrap;
  margin: 60px 0 0 69px; }

.advantage {
  position: relative; }

.advantage:nth-child(1), .advantage:nth-child(3) {
  margin: 0 138px 0 0; }

.advantage:nth-child(1), .advantage:nth-child(2) {
  margin-bottom: 65px; }

.advantage:before {
  position: absolute;
  display: block;
  content: '';
  margin: 0 0 0 -69px; }

.advantage:nth-child(1):before {
  width: 47px;
  height: 41px;
  background: url(../img/newspaper.png); }

.advantage:nth-child(2):before {
  width: 43px;
  height: 43px;
  background: url(../img/calendar.png); }

.advantage:nth-child(3):before {
  width: 47px;
  height: 47px;
  background: url(../img/envelope.png); }

.advantage:nth-child(4):before {
  width: 47px;
  height: 49px;
  background: url(../img/payment.png); }

.advantage_name {
  font-family: 'SF-UI-Display-Heavy';
  font-size: 14px;
  line-height: 22px;
  color: #252525;
  text-transform: uppercase;
  margin: 0 0 13px 0; }

.advantage_text {
  font-family: 'SF-UI-Display-Light';
  font-size: 15px;
  line-height: 21px;
  width: 285px; }

.advantage:nth-child(2) .advantage_text {
  width: 350px; }

.advantages_list-download {
  margin: 60px 0 0 98px; }
  .advantages_list-download .advantage:nth-child(1) {
    margin-right: 0; }
    .advantages_list-download .advantage:nth-child(1) .advantage_name {
      width: 181px; }
  .advantages_list-download .advantage:nth-child(2) {
    margin-bottom: 0; }
  .advantages_list-download .advantage:nth-child(1):before {
    width: 46px;
    height: 41px;
    background: url(../img/sketching.png); }
  .advantages_list-download .advantage:nth-child(2):before {
    width: 37px;
    height: 45px;
    background: url(../img/book.png); }
  .advantages_list-download .advantage_name {
    margin: 0 0 17px 0; }

.advantage_download {
  font-size: 14px;
  color: #00bcd4;
  display: flex;
  align-items: center; }
  .advantage_download .fa-download {
    font-size: 10px;
    margin: 0px 0 0px 12px; }
  .advantage_download:hover, .advantage_download:active, .advantage_download:focus {
    color: #00bcd4; }

.advantage_download__first {
  margin: 0 0 13px 0; }

/*ЧЕТВЁРТЫЙ ЭКРАН*/
#services {
  background: url(../img/bg2.png) no-repeat center/100% auto;
  color: #fff;
  padding: 112px 0 121px 0; }
  #services h2 {
    color: #fff;
    width: 250px; }

.services_list {
  display: flex;
  margin: -12px 0 0 337px; }

.services_item {
  width: 262px;
  height: 155px;
  border-radius: 7px;
  background: #fff;
  box-shadow: 0px 20px 40px 0px rgba(48, 55, 62, 0.3);
  text-align: center;
  font-size: 12px;
  line-height: 21px;
  text-transform: uppercase;
  color: #555;
  position: relative;
  padding: 75px 0 0 0; }

.services_item:not(:last-child) {
  margin: 0 15px 0 0; }

.services_item:before {
  position: absolute;
  display: block;
  content: '';
  width: 88px;
  height: 88px;
  border-radius: 50%;
  box-shadow: 0px 4px 24px 0px rgba(9, 111, 124, 0.29), 0 0 0 14px rgba(255, 255, 255, 0.11);
  margin: -119px 0 0 87px; }

.services_item:nth-child(1):before {
  background: url(../img/crossword.png) no-repeat center, -moz-linear-gradient(113deg, #318ed1 0%, #01d3ee 100%);
  background: url(../img/crossword.png) no-repeat center, -webkit-linear-gradient(113deg, #318ed1 0%, #01d3ee 100%);
  background: url(../img/crossword.png) no-repeat center, -ms-linear-gradient(113deg, #318ed1 0%, #01d3ee 100%); }

.services_item:nth-child(2):before {
  background: url(../img/book-white.png) no-repeat center, -moz-linear-gradient(113deg, #318ed1 0%, #01d3ee 100%);
  background: url(../img/book-white.png) no-repeat center, -webkit-linear-gradient(113deg, #318ed1 0%, #01d3ee 100%);
  background: url(../img/book-white.png) no-repeat center, -ms-linear-gradient(113deg, #318ed1 0%, #01d3ee 100%); }

.services_item:nth-child(3):before {
  background: url(../img/clock.png) no-repeat center, -moz-linear-gradient(113deg, #318ed1 0%, #01d3ee 100%);
  background: url(../img/clock.png) no-repeat center, -webkit-linear-gradient(113deg, #318ed1 0%, #01d3ee 100%);
  background: url(../img/clock.png) no-repeat center, -ms-linear-gradient(113deg, #318ed1 0%, #01d3ee 100%); }

.services_item:nth-child(2) div, .services_item:nth-child(3) div {
  padding: 0 40px; }

.services_item:nth-child(2) div {
  margin: 10px 0 0 0; }

/*ПЯТЫЙ ЭКРАН*/
#steps {
  background: #fff;
  padding: 110px 0 100px 0;
  overflow: hidden; }
  #steps h2 {
    width: 195px; }

.blue-line, .blue-spot {
  position: absolute; }

.blue-line {
  margin: -815px 0 0 -375px; }

.blue-spot {
  margin: -50px 0 0 950px; }

.bg-circles {
  margin: 242px 0 0 -92px; }
  .bg-circles div {
    background: #048c96;
    border-radius: 50%; }
  .bg-circles div:nth-child(1) {
    width: 7px;
    height: 7px; }
  .bg-circles div:nth-child(2) {
    width: 14px;
    height: 14px;
    margin: 2px 0 0 -58px; }
  .bg-circles div:nth-child(3) {
    width: 14px;
    height: 14px;
    margin: 9px 0 0 -18px; }
  .bg-circles div:nth-child(4) {
    width: 7px;
    height: 7px;
    margin: 68px 0 0 -33px; }
  .bg-circles div:nth-child(5) {
    width: 24px;
    height: 24px;
    margin: -7px 0 0 113px; }
  .bg-circles div:nth-child(6) {
    width: 7px;
    height: 7px;
    margin: 57px 0 0 65px; }

.steps {
  box-shadow: 0px 12px 40px 0px rgba(200, 216, 229, 0.5);
  border-radius: 3px;
  background: #fff;
  margin: 70px 0 62px -48px;
  padding: 0 0 27px 30px;
  width: 1239px; }

.step, .steps_wrap, .steps {
  display: flex; }

.step {
  padding: 5px 0 0 0;
  position: relative; }

.step:after {
  position: absolute;
  display: block;
  content: '';
  width: 1px;
  height: 125px;
  background: rgba(0, 0, 0, 0.05);
  margin: 25px 0 0 205px; }

.step-two:after {
  margin: 25px 0 0 255px; }

.step-five:after {
  display: none; }

.step_number {
  font-family: 'SF-UI-Display-Heavy';
  font-size: 72px;
  color: #048c96;
  position: relative; }

.step_number:before {
  position: absolute;
  display: block;
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  box-shadow: 0px 4px 21px 0px rgba(9, 111, 124, 0.2);
  background: #048c96;
  margin: -11px 0 0 18px; }

.step-four .step_number:before {
  margin: -11px 0 0 24px; }

.step_number-text {
  font-size: 14px;
  color: #048c96;
  margin: 20px 10px 0 0; }

.step_text {
  font-family: 'SF-UI-Display-Light';
  font-size: 15px;
  line-height: 21px;
  margin: 40px 40px 0 0; }

.step-two .step_text {
  width: 155px; }

.step-four .step_text {
  width: 101px; }

.step-three .step_text {
  width: 108px; }

#steps .button {
  width: 278px;
  height: 49px;
  margin: 0 auto; }

/*ШЕСТОЙ ЭКРАН*/
#recommendation {
  background: #f6f6f8;
  position: relative;
  overflow: hidden; }

#recommendation .bg-img {
  top: 0;
  clip-path: circle(570px at 300px 100px);
  margin: -70px 0 0 -395px; }

.recommendation {
  margin: 120px 0 125px 40px; }
  .recommendation h2 {
    line-height: 50px; }
  .recommendation div {
    font-family: 'SF-UI-Display-Regular';
    font-size: 24px;
    line-height: 40px;
    text-transform: uppercase;
    color: #252525;
    margin: 3px 0 0 0; }
  .recommendation:after {
    display: block;
    content: '';
    width: 120px;
    height: 3px;
    background: #048c96;
    margin: 38px 0 0 0; }

/*СЕДЬМОЙ ЭКРАН*/
#example {
  background: #fff;
  padding: 110px 0 100px 0;
  overflow: hidden; }

.circles1, .circles2 {
  position: absolute; }
  .circles1 div, .circles2 div {
    background: #048c96;
    border-radius: 50%; }

.circles1 {
  margin: 304px 0 0 242px; }
  .circles1 div:nth-child(1) {
    width: 8px;
    height: 8px; }
  .circles1 div:nth-child(2) {
    width: 28px;
    height: 28px;
    margin: 18px 0 0 98px; }

.circles2 {
  margin: -35px 0 0 1003px; }
  .circles2 div:nth-child(1) {
    width: 8px;
    height: 8px; }
  .circles2 div:nth-child(2) {
    width: 14px;
    height: 14px;
    margin: 12px 0 0 217px; }
  .circles2 div:nth-child(3) {
    width: 14px;
    height: 14px;
    margin: 4px 0 0 170px; }
  .circles2 div:nth-child(4) {
    width: 7px;
    height: 7px;
    margin: 22px 0 0 193px; }
  .circles2 div:nth-child(5) {
    width: 7px;
    height: 7px;
    margin: 64px 0 0 178px; }

.example-text {
  line-height: 24px;
  color: #252525;
  margin: 0 0 36px 0;
  width: 300px; }
  .example-text:before {
    display: block;
    content: '';
    width: 40px;
    height: 2px;
    background: #048c96;
    margin: 12px 0 15px 0; }

#example .button {
  width: 244px; }

.option {
  margin: 10px 0 0 0; }

.option-two {
  margin: 10px 0 0 9px; }

.option_block {
  width: 366px;
  border-radius: 3px;
  box-shadow: 0px 20px 40px 0px rgba(200, 216, 229, 0.5); }

.option_head {
  font-family: 'SF-UI-Display-Heavy';
  font-size: 17px;
  color: #fff;
  text-transform: uppercase;
  padding: 12px 0 20px 35px;
  border-radius: 3px; }

.option-one .option_head {
  background: url(../img/option-header1.png) no-repeat; }

.option-two .option_head {
  background: url(../img/option-header2.png) no-repeat; }

.option_content {
  padding: 13px 30px 35px 35px; }

.option_day {
  font-size: 13px; }

.option_channel {
  font-family: 'SF-UI-Display-Heavy';
  font-size: 15px;
  text-transform: uppercase;
  color: #252525;
  margin: 12px 0 5px 0; }

.timetable {
  font-size: 13px;
  line-height: 18px;
  color: #252525; }

.timetable_item:not(:last-child) {
  margin: 0 0 6px 0; }

.timetable_time {
  font-family: 'SF-UI-Display-Heavy';
  text-transform: uppercase; }

.bolder {
  font-family: 'SF-UI-Display-Heavy'; }

.upper {
  text-transform: uppercase; }

.option_download {
  width: 42px;
  height: 42px;
  background: #01D3EE;
  border-radius: 50%;
  box-shadow: 0px 0 0px 8px rgba(1, 211, 238, 0.24);
  text-align: center;
  padding: 8px 0;
  margin: -21px 0 0 300px;
  cursor: pointer; }
  .option_download .fa-download {
    font-size: 14px;
    color: #fff; }

/*ВОСЬМОЙ ЭКРАН*/
#registration {
  background: url(../img/bg4.png) no-repeat 0px -100px;
  padding: 120px 0 117px 0; }

.new-login {
  display: flex;
  width: 924px;
  height: 597px;
  border-radius: 20px;
  box-shadow: 0px 20px 73px 0px rgba(48, 55, 62, 0.64);
  background: #fff;
  margin: 0 auto;
  overflow: hidden; }

.new, .login, .get-tv {
  position: relative; }
  .new form, .login form, .get-tv form {
    margin: 38px 0 0 0;
    position: relative; }
  .new input, .login input, .get-tv input {
    display: block;
    width: 100%;
    background: transparent;
    border: none;
    border-bottom: 1px solid #cbcece;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #4a4a4a;
    margin: 0 0 40px 0;
    padding: 0 0 12px 0; }
  .new input:focus, .login input:focus, .get-tv input:focus {
    border-bottom: 1px solid #01D3EE;
    font-weight: 500; }
  .new ::-webkit-input-placeholder, .login ::-webkit-input-placeholder, .get-tv ::-webkit-input-placeholder {
    color: #afafaf;
    opacity: 1;
    font-weight: 400; }
  .new ::-moz-placeholder, .login ::-moz-placeholder, .get-tv ::-moz-placeholder {
    color: #afafaf;
    opacity: 1;
    font-weight: 400; }
  .new :-moz-placeholder, .login :-moz-placeholder, .get-tv :-moz-placeholder {
    color: #afafaf;
    opacity: 1;
    font-weight: 400; }
  .new :-ms-input-placeholder, .login :-ms-input-placeholder, .get-tv :-ms-input-placeholder {
    color: #afafaf;
    opacity: 1;
    font-weight: 400; }
  .new input:focus::-webkit-input-placeholder, .login input:focus::-webkit-input-placeholder, .get-tv input:focus::-webkit-input-placeholder {
    color: #4a4a4a;
    opacity: 1; }
  .new input:focus::-moz-placeholder, .login input:focus::-moz-placeholder, .get-tv input:focus::-moz-placeholder {
    color: #4a4a4a;
    opacity: 1; }
  .new input:focus:-moz-placeholder, .login input:focus:-moz-placeholder, .get-tv input:focus:-moz-placeholder {
    color: #4a4a4a;
    opacity: 1; }
  .new input:focus:-ms-input-placeholder, .login input:focus:-ms-input-placeholder, .get-tv input:focus:-ms-input-placeholder {
    color: #4a4a4a;
    opacity: 1; }
  .new label.error, .login label.error, .get-tv label.error {
    position: absolute;
    font-family: 'Raleway', sans-serif;
    font-size: 11px;
    color: #ff6565;
    font-weight: 400;
    margin: -40px 0 0 0; }
  .new .check, .login .check, .get-tv .check {
    position: absolute;
    right: 0;
    margin: -75px 0 0 0; }
  .new .fa-check, .login .fa-check, .get-tv .fa-check {
    font-size: 11px;
    color: #27ae60; }
  .new input:valid + .check, .new input:valid + label.error + .check, .login input:valid + .check, .login input:valid + label.error + .check, .get-tv input:valid + .check, .get-tv input:valid + label.error + .check {
    opacity: 1; }
  .new input:invalid + .check, .new input:invalid + label.error + .check, .login input:invalid + .check, .login input:invalid + label.error + .check, .get-tv input:invalid + .check, .get-tv input:invalid + label.error + .check {
    opacity: 0;
    transition: opacity ease .3s; }

.new-passive, .login-passive {
  position: absolute;
  width: 100%;
  top: 0; }

.new {
  width: 615px;
  transition: all 1s ease; }

.new_title, .get-tv_title {
  font-family: 'SF-UI-Display-Medium';
  font-size: 22px;
  color: #252525;
  line-height: 28px;
  width: 360px; }

.new-active {
  padding: 55px 68px 0 51px; }

.new-passive {
  margin: 230px 0 0 0;
  display: none; }
  .new-passive .new_title {
    width: 232px;
    margin: 0 auto; }
  .new-passive .button {
    width: 232px;
    margin: 36px auto 0 auto; }

.login {
  width: 309px;
  background: #01D3EE url(../img/form-bg.png) no-repeat;
  background-position: -170px 0%;
  background-size: 200% 120%;
  height: 100%;
  color: #fff;
  transition: all 1s ease; }
  .login input {
    color: #fff;
    border-bottom: 1px solid #dfdfdf; }
  .login input:focus {
    border-bottom: 1px solid #fff; }
  .login input:nth-child(2) {
    margin-bottom: 26px; }
  .login input[type=checkbox] {
    display: none; }
  .login input[type=checkbox] + .checkbox-label:before {
    display: inline-block;
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    font-size: 12px;
    color: transparent;
    text-align: center;
    width: 25px;
    height: 25px;
    background: #e8ebec;
    border: none;
    border-radius: 4px;
    box-shadow: inset 1px 1.732px 0px 0px rgba(0, 0, 0, 0.09);
    padding: 4px 0;
    margin: 0 15px 0 0;
    transition: color ease .3s; }
  .login input[type=checkbox]:checked + label:before {
    color: #048c96; }
  .login label {
    font-family: 'Raleway', sans-serif;
    font-size: 13px;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin: 0;
    cursor: pointer; }
  .login ::-webkit-input-placeholder {
    color: #f5f4f4; }
  .login ::-moz-placeholder {
    color: #f5f4f4; }
  .login :-moz-placeholder {
    color: #f5f4f4; }
  .login :-ms-input-placeholder {
    color: #f5f4f4; }
  .login input:focus::-webkit-input-placeholder {
    color: #fff; }
  .login input:focus::-moz-placeholder {
    color: #fff; }
  .login input:focus:-moz-placeholder {
    color: #fff; }
  .login input:focus:-ms-input-placeholder {
    color: #fff; }
  .login .fa-check {
    color: #fff; }
  .login .form-button:hover {
    background: #f9f9f9; }
  .login .button .arrow-right img {
    right: 0;
    left: auto; }

.login_title {
  font-size: 22px; }

.login-active {
  padding: 148px 48px 0 54px;
  display: none; }
  .login-active .form-button {
    background: #fff;
    color: #01D3EE;
    width: 484px;
    margin: 30px 0 0 0; }

.login-passive {
  text-align: center;
  padding: 180px 0 0 0; }
  .login-passive .button {
    margin: 26px auto 0 auto;
    background: #fff;
    color: #01D3EE;
    width: 128px;
    box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.15); }

.login-proposal, .reg-proposal {
  display: none;
  font-family: 'Raleway', sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: #4a4a4a;
  text-align: center;
  margin: 21px 0 0 0; }
  .login-proposal span, .reg-proposal span {
    color: #01D3EE;
    cursor: pointer; }

.reg-proposal {
  color: #fff;
  cursor: pointer; }

/*ДЕВЯТЫЙ ЭКРАН*/
#questions {
  background: #fff;
  padding: 110px 0 118px 0; }

.questions {
  margin: 15px 0 0 18px; }

.questions_item {
  border-radius: 0px 3px 3px 0px;
  box-shadow: 0px 20px 40px 0px rgba(200, 216, 229, 0.5);
  border-left: 3px solid #01D3EE;
  width: 847px;
  background: #fff;
  padding: 13px 45px 10px 22px;
  display: flex; }

.questions_item:not(:last-child) {
  margin: 0 0 20px 0;
  transition: all 0.4s ease; }

.questions_symbol {
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  color: #01D3EE;
  position: relative;
  font-size: 25px;
  margin: -5px 27px 0 0;
  cursor: pointer; }

.plus, .minus {
  opacity: 0;
  transition: all 0.4s ease; }

.plus {
  position: relative;
  z-index: 2; }

.minus {
  position: absolute;
  top: -2px;
  z-index: 1; }

.active-symbol {
  opacity: 1;
  transition: all 0.4s ease; }

.question {
  font-family: 'SF-UI-Display-Bold';
  font-size: 18px;
  line-height: 26px;
  color: #252525;
  text-transform: uppercase;
  cursor: pointer; }

.answer {
  font-family: 'SF-UI-Display-Light';
  font-size: 14px;
  line-height: 22px;
  margin: 19px 0 0 0;
  display: none; }

.open-question {
  margin-bottom: 32px !important;
  transition: all 0.4s ease; }

/*ДЕСЯТЫЙ ЭКРАН*/
#testing {
  background: #f6f6f8 url(../img/bg5.png) no-repeat 100% top;
  padding: 112px 0 117px 0; }

#testing h2 {
  width: 640px; }
  #testing h2 span {
    font-size: 36px;
    line-height: 45px; }

.testing_text {
  color: #252525;
  line-height: 24px;
  width: 520px;
  margin: 0 0 37px 0; }

.testing_text:before {
  display: block;
  content: '';
  width: 40px;
  height: 2px;
  background: #048c96;
  margin: 11px 0 14px 0; }

#testing .button {
  width: 278px; }

/*ОДИННАДЦАТЫЙ ЭКРАН*/
#contacts {
  background: url(../img/contacts-map.png) no-repeat center top/cover;
  color: #fff;
  position: relative;
  overflow: hidden;
  padding: 120px 0 115px 0; }
  #contacts h2 {
    color: #fff; }

.contacts {
  width: 464px;
  border-radius: 3px;
  box-shadow: 0px 20px 40px 0px rgba(48, 55, 62, 0.4);
  background: rgba(4, 140, 150, 0.902);
  padding: 52px 0 62px 60px; }

.contacts_text {
  line-height: 24px;
  width: 319px;
  margin: 0 0 60px 0; }
  .contacts_text:before {
    display: block;
    content: '';
    width: 40px;
    height: 2px;
    background: #fff;
    margin: 12px 0 16px 0; }

.contacts_info div:not(:last-child) {
  margin: 0 0 17px 0; }

.contacts_name {
  font-family: 'SF-UI-Display-Bold'; }

.contacts_address, .contacts_tel, .contacts_mail {
  display: flex;
  align-items: center; }

.contacts_info .fas {
  font-size: 13px;
  margin: 0 14px 0 0; }

.contacts_tel .fa-phone {
  transform: rotate(90deg); }

.contacts_info a, .contacts_info a:hover, .contacts_info a:active, .contacts_info a:focus,
.footer-contacts a, .footer-contacts a:hover, .footer-contacts a:active, .footer-contacts a:focus {
  color: #fff; }

.contacts-img {
  position: absolute;
  top: -46px;
  left: 340px; }

/*ДВЕНАДЦАТЫЙЙ ЭКРАН*/
footer {
  color: #fff;
  flex: 0 0 auto;
  margin-top: -1px; }

.footer-menu {
  background: #03474f;
  padding: 37px 0; }

.footer-menu_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'SF-UI-Display-Light'; }
  .footer-menu_container nav {
    margin: 0; }
  .footer-menu_container nav a {
    font-size: 12px; }

.footer-contacts {
  display: flex;
  font-size: 14px; }
  .footer-contacts .contacts_tel {
    margin: 0 24px 0 0; }

.footer-contacts .fas {
  font-size: 11px;
  margin: 0 14px 0 0; }

.copyright {
  background: #05383e;
  font-family: 'SF-UI-Display-Light';
  font-size: 12px;
  text-align: center;
  height: 76px;
  padding: 30px 0; }

/*Модальное окно Регистрация/Войти*/
.box-modal {
  width: auto;
  padding: 0;
  background: unset;
  box-shadow: unset;
  border-radius: 20px;
  color: unset;
  font: unset; }

.box-modal_close {
  color: #fff;
  font-size: 20px;
  right: 15px;
  top: 13px; }

.box-modal_close:hover {
  color: #fff; }

/*Модальное окно Получить телепрограмму*/
#get-tv-modal {
  width: 536px;
  border-radius: 20px;
  box-shadow: 0px 20px 73px 0px rgba(48, 55, 62, 0.64);
  background: #fff;
  padding: 50px; }
  #get-tv-modal .form-button {
    width: 100%; }
  #get-tv-modal .box-modal_close {
    color: #01D3EE; }

/*Overlay - Подтверждение*/
#overlay {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.68);
  left: 0px;
  top: 0px;
  cursor: pointer; }

#thx {
  position: absolute;
  top: 35%;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0px 20px 73px 0px rgba(48, 55, 62, 0.64);
  border-radius: 8px;
  width: 580px;
  text-align: center; }

.thx_img-container {
  position: relative; }

.thx_circles {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 150px;
  margin: auto; }
  .thx_circles div {
    background: #1891a2;
    border-radius: 50%; }
  .thx_circles div:nth-child(1), .thx_circles div:nth-child(2),
  .thx_circles div:nth-child(4), .thx_circles div:nth-child(6), .thx_circles div:nth-child(8) {
    width: 5px;
    height: 5px; }
  .thx_circles div:nth-child(3), .thx_circles div:nth-child(5) {
    width: 9px;
    height: 9px; }
  .thx_circles div:nth-child(7) {
    width: 16px;
    height: 16px; }
  .thx_circles div:nth-child(1) {
    margin: -17px 0 0 80px; }
  .thx_circles div:nth-child(2) {
    margin: 20px 0 0 36px; }
  .thx_circles div:nth-child(3) {
    margin: -2px 0 0 -3px; }
  .thx_circles div:nth-child(4) {
    margin: -4px 0 0 134px; }
  .thx_circles div:nth-child(5) {
    margin: 19px 0 0 134px; }
  .thx_circles div:nth-child(6) {
    margin: 35px 0 0 13px; }
  .thx_circles div:nth-child(7) {
    margin: -5px 0 0 113px; }
  .thx_circles div:nth-child(8) {
    margin: 27px 0 0 80px; }

.thx_img {
  position: relative;
  width: 113px;
  height: 113px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0px 4px 24px 0px rgba(9, 111, 124, 0.29);
  text-align: center;
  padding: 38px 0;
  margin: -56.6px auto 0 auto; }

.thx_text {
  font-size: 19px;
  line-height: 30px;
  color: #252525;
  width: 330px;
  margin: 28px auto 22px auto; }

.thx_close {
  font-size: 14px;
  color: #01D3EE;
  margin: 0 0 30px 0; }

/*Загрузка...*/
#loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(240, 240, 240, 0.91);
  overflow: hidden;
  display: none;
  z-index: 9999; }

#loader img {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 50%;
  margin-left: -25px;
  top: 50%;
  margin-top: -25px; }

/*СТРАНИЦА ЛИЧНЫЙ КАБИНЕТ*/
.header-cabinet {
  background: #03474f;
  padding: 17px 0 21px 0; }

.profile-info {
  display: flex;
  margin: 0 25px 0 70px;
  color: #fff; }

.profile-name {
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  font-weight: 600; }

.userpic {
  font-size: 14px;
  margin: 0 9px 0 0; }

a.exit {
  font-family: 'SF-UI-Display-Light';
  font-size: 14px;
  color: #fff; }

#cabinet {
  background: #f7f9f9;
  color: #252525;
  padding: 63px 0 80px 0;
  height: 100%; }

.cabinet_status {
  font-family: 'SF-UI-Display-Bold';
  font-size: 26px;
  line-height: 30px; }

.cabinet_content {
  margin: 30px 0 55px 0; }
  .cabinet_content .button {
    width: 187px;
    margin: 0 0 0 auto; }

.content {
  margin: 0 0 30px 0;
  position: relative; }

.tabs_menu {
  margin: 0 0 35px 0;
  height: 37px;
  overflow: hidden; }
  .tabs_menu:before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #e4e3e3;
    position: absolute;
    top: 35.4px;
    z-index: 1; }
  .tabs_menu li {
    display: inline-block; }

.tabs_menu li a {
  display: block;
  font-family: 'SF-UI-Display-Light';
  font-size: 15px;
  color: #a6a5a5;
  border-bottom: 3px solid transparent;
  padding: 0 30px 12px 0;
  margin: 0 44px 0 0;
  position: relative;
  z-index: 2;
  transition: all 0.3s ease; }

.tabs_menu .active-tab {
  font-family: 'SF-UI-Display-Medium';
  font-size: 16px;
  color: #252525;
  border-bottom: 3px solid #01D3EE; }

.flexMenu-viewMore a {
  margin: 0 !important;
  padding: 0 0 12px 0px !important;
  color: #01D3EE !important;
  font-size: 16px !important; }

.flexMenu-viewMore > a:after {
  display: block;
  content: "";
  position: absolute;
  right: -17px;
  top: 6px;
  width: 7px;
  height: 9px;
  background: url(../img/arrow-right.png) no-repeat right top;
  transform: rotate(90deg); }

.flexMenu-popup {
  right: 0;
  background: #fff;
  text-align: right;
  padding: 0 0 0px 0;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px; }

.flexMenu-popup > li > a, .flexMenu-popup > li {
  display: block;
  margin: 0; }

.flexMenu-popup > li {
  padding: 12px 18px 0px 18px; }

.flexMenu-popup > li:hover {
  background: #fbfbfb; }

.flexMenu-popup > li > a {
  padding: 0 4px 12px 34px !important;
  border-bottom: 1px solid #eeeeee;
  font-family: 'SF-UI-Display-Regular';
  font-size: 14px;
  color: #2d2d2d !important; }

.flexMenu-popup > li:last-child > a {
  border-bottom: 1px solid transparent; }

.flexMenu-popup > li:last-child > a.active-tab {
  border-bottom: 3px solid #01d3ee;
  margin-bottom: 18px !important; }

#tab2, #tab3, #tab4, #tab5, #tab6, #tab7, #tab8, #tab9 {
  display: none; }

.tab-wrap {
  width: 100%;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  padding: 30px 34px 20px 34px; }

.content_item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eeeeee;
  padding: 0 0 19px 0; }

.content_item:not(:first-child) {
  margin: 15px 0 0 0; }

.content_item-name {
  font-size: 14px;
  text-transform: uppercase; }

.content_item-info {
  display: flex;
  align-items: center; }

.content_item-date, .choose-all_text {
  color: #555;
  font-size: 14px; }

.content_item-checkbox {
  margin: -4px 35px 0 68px; }
  .content_item-checkbox input[type=checkbox] {
    display: none; }
  .content_item-checkbox input[type=checkbox] + .checkbox-label:before {
    display: inline-block;
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    font-size: 8px;
    color: transparent;
    text-align: center;
    width: 16px;
    height: 16px;
    background: #e8ebec;
    border: none;
    border-radius: 4px;
    box-shadow: inset 1px 1.732px 0px 0px rgba(0, 0, 0, 0.09);
    padding: 3px 0;
    transition: color ease .3s; }
  .content_item-checkbox input[type=checkbox]:checked + label:before {
    color: #048c96; }
  .content_item-checkbox label {
    margin: 0;
    cursor: pointer; }

.content_item-download {
  font-size: 11px;
  color: #01D3EE;
  cursor: pointer; }

.choose-all {
  display: flex;
  justify-content: flex-end;
  padding-right: 11px;
  margin: 20px 0 0 0; }

.cabinet_one-news {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  line-height: 24px;
  background: #fff;
  border-radius: 0 3px 3px 0;
  border-left: 3px solid #01D3EE;
  padding: 24px 39px 28px 35px; }

.cabinet_one-news:not(:last-child) {
  margin: 0 0 10px 0; }

.news-text {
  width: 815px; }

.news-date {
  color: #555; }

/*Прокрутка страницы вверх*/
.go-up {
  display: none;
  position: fixed;
  bottom: 50px;
  right: 10px;
  z-index: 200;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background: #01D3EE;
  cursor: pointer;
  font-size: 25px;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  box-shadow: 2.868px 4.096px 15px 0px rgba(0, 0, 0, 0.26); }
  .go-up div {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    border: 2px solid #048c96;
    padding: 8px 0;
    margin: 0 auto; }

.hvr-bob {
  transform: perspective(1px) translateZ(0); }

.hvr-bob:hover, .hvr-bob:focus, .hvr-bob:active {
  animation-name: hvr-bob-float, hvr-bob;
  animation-duration: .3s, 1.5s;
  animation-delay: 0s, .3s;
  animation-timing-function: ease-out, ease-in-out;
  animation-iteration-count: 1, infinite;
  animation-fill-mode: forwards;
  animation-direction: normal, alternate; }

@keyframes hvr-bob-float {
  100% {
    transform: translateY(-8px); } }

@keyframes hvr-bob {
  0% {
    transform: translateY(-8px); }
  50% {
    transform: translateY(-4px); }
  100% {
    transform: translateY(-8px); } }
